import { gql } from '../../__generated__/gql'

export const SONG_VIDEO_QUERY = gql(`
  query songVideo($id: Int!) {
    song(id: $id) {
      id
      video
      thumbnail
    }
  }
`)
