import { gql } from '../../__generated__/gql'
export const SONG_TAB_INFOS_QUERY = gql(`
  query songTabInfos($songId: Int!) {
    songTabInfos(songId: $songId) {
      capo
      effects
      id
      instrument
      notesOfString
      shapes
      shapesUsed
      soundsliceId
      tuning
    }
  }
`)
