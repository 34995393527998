import { gql } from '../../__generated__/gql'

export const LOGIN_SESSION_QUERY = gql(`
  query loginSessions {
    loginSessions {
        id
        deviceType
        deviceName
        lastActive
        current
      }
  }
`)
