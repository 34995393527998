import { gql } from '../../__generated__/gql'
export const SONG_LYRICS_QUERY = gql(`
  query lyricsCharts($songId: Int!) {
    lyricsCharts(songId: $songId) {
      name
      html
      repeat
      acronym
    }
  }
`)
