import { gql } from '../../__generated__/gql'
export const SONG_CHORDS_QUERY = gql(`
  query chordCharts($songId: Int!, $key: String, $capo: Int) {
    chordCharts(songId: $songId, key: $key, capo: $capo) {
      name
      html
      repeat
      acronym
    }
  }
`)
