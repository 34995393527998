import React, { createContext, useReducer, Suspense } from 'react'
import { t } from './i18n'
import { Modal } from '../components/modals/Modal'

export const ModalsContext = createContext(null)

const reducer = (state, action) => {
  switch (action.type) {
    case 'open':
      return [...state, { ...action.payload }]
    case 'close':
      const deleteAmount =
        typeof action.payload === 'number' ? action.payload : -1
      return state.slice(0, deleteAmount)
    default:
      return state
  }
}

interface openModalProps {
  name: string
  props: any
  onModalClose: (any) => void
  modalTitle: string
  modifier: string
  hideCloseButton: boolean
}

export const ModalsProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, [])
  const modalsAmount = state.length

  const openModal = ({
    name,
    props,
    onModalClose,
    modalTitle,
    modifier,
    hideCloseButton
  }: openModalProps) => {
    dispatch({
      type: 'open',
      payload: {
        name,
        props,
        modalTitle,
        onModalClose,
        modifier,
        hideCloseButton
      }
    })
  }

  const closeModal = (deleteAmount?: number) => {
    dispatch({
      type: 'close',
      payload: deleteAmount || -1
    })
  }

  if (!modalsAmount) {
    return (
      <ModalsContext.Provider value={{ closeModal, openModal }}>
        {props.children}
      </ModalsContext.Provider>
    )
  }

  const {
    name,
    props: modalProps,
    modalTitle,
    onModalClose,
    modifier,
    hideCloseButton
  } = state[modalsAmount - 1]

  const CurrentModal = React.lazy(
    () => import(`../components/modals/${name}.tsx`)
  )

  return (
    <ModalsContext.Provider
      value={{ closeModal, openModal, onModalClose, modifier, name }}
    >
      {props.children}
      <Suspense fallback={<></>}>
        <Modal
          isOpen={true}
          title={t(modalTitle)}
          onHide={() => {
            closeModal()
            onModalClose?.()
          }}
          modifier={modifier || null}
          hideCloseButton={hideCloseButton}
        >
          <CurrentModal {...modalProps} />
        </Modal>
      </Suspense>
    </ModalsContext.Provider>
  )
}
