import { ApolloError } from '@apollo/client'
import { useToasts } from '../../components/complex/Toast'
import { errorMessage } from '../errorMessage'

const useErrorHandling = (): ((error: ApolloError) => void) => {
  const { addToast } = useToasts()

  return (error: ApolloError) => {
    const message = errorMessage(error)
    addToast(message, 'error')
  }
}

export default useErrorHandling
