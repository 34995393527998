import { NextPage } from 'next'
import { ApolloError } from '@apollo/client'
import Layout from '../components/Layout'
import styles from './_index.module.scss'

import background from '../../public/images/bg404.png'
import notes from '../../public/images/notes.png'
import img404 from '../../public/images/img404.png'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import Image from 'next/legacy/image'

const { publicRuntimeConfig } = getConfig()

interface ErrorPageProps {
  error?: ApolloError
  statusCode: number
}

export const sendErrorToRollbar = async (error: string) => {
  const Rollbar = (await import('rollbar')).default

  const RollbarObject = new Rollbar({
    accessToken: `${publicRuntimeConfig.rollbarClientToken}`,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: `${process.env.NEXT_PUBLIC_ENV}`
    },
    autoInstrument: {
      log: false
    }
  })
  RollbarObject.error(error)
}

const ErrorPage: NextPage<ErrorPageProps> = (props) => {
  const router = useRouter()
  if (process.env.NODE_ENV !== 'development') {
    sendErrorToRollbar(
      `Error ${props.statusCode === 200 ? 404 : props.statusCode} ${
        (props.error ? props.error.message : null) || props.error || ''
      }`
    )
  }

  return (
    <Layout>
      <div className={classNames('container content', styles.errorPage)}>
        <div className={styles.errorBgContainer}>
          <Image
            className={styles.errorBg}
            src={background}
            alt=""
            layout="fill"
            quality={100}
          />
        </div>

        <div className={styles.errorPageContent}>
          <div className={styles.errorImgs}>
            <div className={styles.notesContainer}>
              <Image
                src={notes}
                className={styles.notes}
                alt=""
                width={320}
                height={368}
              />
            </div>
            <div className={styles.img404Container}>
              <Image
                src={img404}
                className={styles.img404}
                alt=""
                width={400}
                height={156}
              />
            </div>

            <h3>on the floor</h3>
          </div>
          <div className={styles.errorText}>
            <h1>Uh oh!</h1>
            <h2>It looks like that link is out of sync.</h2>
            <p>We couldn’t find the link you were looking for.</p>
            <span onClick={() => router.back()}>Go back</span>
          </div>
        </div>
      </div>
    </Layout>
  )
}

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404

  return { statusCode }
}

export default ErrorPage
