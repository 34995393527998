import { t } from '../../utils'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CURRENT_USER_QUERY,
  DEACTIVATE_LOGIN_SESSION_MUTATION,
  LOGIN_SESSION_QUERY
} from '../../gql'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import styles from './_devicesModal.module.scss'
import classNames from 'classnames'
import useErrorHandling from '../../utils/hooks/useErrorHandling'
import dayjs from 'dayjs'
import { useAuth } from '../../utils/auth'
import { useEffect } from 'react'
import { LoginSession } from '../../__generated__/graphql'
import { getOperationName } from '@apollo/client/utilities'
import desktopIcon from '@public/images/desktop.svg'
import smartphoneIcon from '@public/images/smartphone.svg'
import questionIcon from '@public/images/question-mark.svg'
import Image from 'next/image'

const deviceIcons = {
  UNKNOWN: questionIcon,
  MOBILE: smartphoneIcon,
  SMARTPHONE: smartphoneIcon,
  DESKTOP: desktopIcon
}

const DevicesModal = () => {
  const errorHandling = useErrorHandling()
  const { currentUser } = useAuth()
  const [queryLoginSession, { data }] = useLazyQuery(LOGIN_SESSION_QUERY, {
    fetchPolicy: 'no-cache'
  })
  const [deactivateLoginSession] = useMutation(
    DEACTIVATE_LOGIN_SESSION_MUTATION,
    {
      onError: errorHandling,
      refetchQueries: [
        getOperationName(CURRENT_USER_QUERY),
        getOperationName(LOGIN_SESSION_QUERY)
      ]
    }
  )
  useEffect(() => {
    queryLoginSession()
  }, [])

  const loginSessions = data?.loginSessions

  const logout = (id: number) => {
    deactivateLoginSession({ variables: { id } })
  }
  return (
    <div className={styles.devicesContainer}>
      {currentUser?.devicesLimitExceeded && (
        <h2>
          {t('Please remove one of the devices from the list below to proceed')}
        </h2>
      )}

      <div
        className={classNames(
          'columns is-mobile is-hidden-mobile',
          styles.tableHeader
        )}
      >
        <p className="column"></p>
        <p className="column is-4">{t('Name')}</p>
        <p className="column">{t('Last Active')}</p>
        <p className="column is-2">{t('Remove')}</p>
      </div>
      {loginSessions?.map((loginSession: LoginSession) => {
        return (
          <div
            key={loginSession.id}
            className={classNames(
              'columns is-mobile',
              styles.deviceRow,
              loginSession.current && styles.currentDeviceRow
            )}
          >
            <div className="column">
              <Image
                alt={loginSession.deviceType}
                src={deviceIcons[loginSession.deviceType] || questionIcon}
              />
            </div>
            <div className="column is-4">
              <p>{t(loginSession.deviceName.toLowerCase())}</p>
              {loginSession.current && (
                <p className={styles.currentDeviceLabel}>
                  {t('Current Device')}
                </p>
              )}
            </div>
            <div className="column ">
              <p>{dayjs(loginSession.lastActive).format('YYYY-MM-DD')}</p>
            </div>
            <button
              className={classNames('column is-2', styles.removeButton)}
              onClick={() => logout(loginSession.id)}
            >
              <FontAwesomeIcon
                aria-hidden="true"
                icon={faTimes}
                color="#E728EA"
                className={styles.removeIcon}
              />
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default DevicesModal
