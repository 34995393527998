import React from 'react'

interface LogoProps {
  width: string | number
  height: string | number
}
export const Logo: React.FC<LogoProps> = (props: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 99.764 76.982"
  >
    <defs>
      <linearGradient
        id="a"
        x1="0.103"
        y1="1.006"
        x2="1.092"
        y2="-0.156"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#a03c95" stopOpacity="0" />
        <stop offset="1" stopColor="#49c7f2" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="-0.053"
        y1="1.193"
        x2="0.931"
        y2="0.031"
        xlinkHref="#a"
      />
      <linearGradient
        id="c"
        x1="-0.208"
        y1="1.417"
        x2="1.399"
        y2="-0.588"
        xlinkHref="#a"
      />
    </defs>
    <g transform="translate(-121.107 -302.976)">
      <g transform="translate(121.107 302.976)">
        <path
          d="M174.314,303.211c11.494,4.64,19.129,12.938,18.66,26.337a24.918,24.918,0,0,1-2.6,9.989c-5.544,11.051-11.515,21.888-17.26,32.839a13.367,13.367,0,0,1-10.03,7.507c-6.361,1.122-13.55-4.317-14.049-10.775a15.047,15.047,0,0,1,1.747-8.553q10.095-18.8,20.023-37.685a40.505,40.505,0,0,0,4.588-12.884A10.95,10.95,0,0,0,174.314,303.211Z"
          transform="translate(-129.723 -303.049)"
          fill="#b700ba"
        />
        <path
          d="M226.852,303.1c7.263,3.2,13.271,7.267,16.456,14.5a24.836,24.836,0,0,1-.517,22.321c-5.716,11.092-11.684,22.054-17.489,33.1a12.657,12.657,0,0,1-12.326,6.905,12.4,12.4,0,0,1-11.221-9.9,12.526,12.526,0,0,1,1.215-8.78q9.9-18.6,19.771-37.214c2.29-4.316,4.326-8.722,5.129-13.613A11.327,11.327,0,0,0,226.852,303.1Z"
          transform="translate(-145.94 -303.015)"
          fill="#b700ba"
        />
        <path
          d="M129.25,302.976c6.047,2.841,11.451,5.875,14.828,11.466,5.359,8.874,5.516,17.9.391,26.884-2.683,4.7-7.079,6.641-12.373,6.116a12.225,12.225,0,0,1-10.669-9.641,12.968,12.968,0,0,1,1.258-9.12c2.711-4.993,5.6-9.915,7.057-15.482C130.618,309.85,131.189,306.505,129.25,302.976Z"
          transform="translate(-121.107 -302.976)"
          fill="#b700ba"
        />
      </g>
      <g transform="translate(121.107 302.976)">
        <path
          d="M174.314,303.211c11.494,4.64,19.129,12.938,18.66,26.337a24.918,24.918,0,0,1-2.6,9.989c-5.544,11.051-11.515,21.888-17.26,32.839a13.367,13.367,0,0,1-10.03,7.507c-6.361,1.122-13.55-4.317-14.049-10.775a15.047,15.047,0,0,1,1.747-8.553q10.095-18.8,20.023-37.685a40.505,40.505,0,0,0,4.588-12.884A10.95,10.95,0,0,0,174.314,303.211Z"
          transform="translate(-129.723 -303.049)"
          fill="url(#a)"
        />
        <path
          d="M226.852,303.1c7.263,3.2,13.271,7.267,16.456,14.5a24.836,24.836,0,0,1-.517,22.321c-5.716,11.092-11.684,22.054-17.489,33.1a12.657,12.657,0,0,1-12.326,6.905,12.4,12.4,0,0,1-11.221-9.9,12.526,12.526,0,0,1,1.215-8.78q9.9-18.6,19.771-37.214c2.29-4.316,4.326-8.722,5.129-13.613A11.327,11.327,0,0,0,226.852,303.1Z"
          transform="translate(-145.94 -303.015)"
          fill="url(#b)"
        />
        <path
          d="M129.25,302.976c6.047,2.841,11.451,5.875,14.828,11.466,5.359,8.874,5.516,17.9.391,26.884-2.683,4.7-7.079,6.641-12.373,6.116a12.225,12.225,0,0,1-10.669-9.641,12.968,12.968,0,0,1,1.258-9.12c2.711-4.993,5.6-9.915,7.057-15.482C130.618,309.85,131.189,306.505,129.25,302.976Z"
          transform="translate(-121.107 -302.976)"
          fill="url(#c)"
        />
      </g>
    </g>
  </svg>
)
