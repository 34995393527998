import { useRef, useEffect, useState, ReactNode } from 'react'
import { createPortal } from 'react-dom'

interface ClientPortalProps {
  selector?: string
  children?: React.ReactNode
}

const ClientPortal: React.FC<ClientPortalProps> = ({ children, selector }) => {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  return mounted ? (createPortal(children, ref.current) as ReactNode) : null
}

ClientPortal.defaultProps = {
  selector: 'body'
}

export default ClientPortal
