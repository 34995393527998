import classnames from 'classnames'
import {
  useCallback,
  createContext,
  useContext,
  useState,
  ReactNode
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import styles from './_toast.module.scss'

import ClientPortal from '../ClientPortal'

type ToastType = 'primary' | 'danger' | 'error'

interface ToastContextProps {
  addToast: (
    content: ReactNode | string,
    type?: ToastType,
    onClose?: () => void
  ) => void
  removeToast: (id) => void
}

const ToastContext = createContext<Partial<ToastContextProps>>({})

const ToastContainer = ({ toasts }) => {
  return (
    toasts.length > 0 && (
      <ClientPortal selector="body">
        <div className={styles.toastContainer}>
          {toasts.map((item, index) => (
            <Toast
              type={item.type}
              content={item.content}
              key={`${item.id}-${index}`}
              id={item.id}
              onClose={item.onClose}
            >
              {item.content}
            </Toast>
          ))}
        </div>
      </ClientPortal>
    )
  )
}

export interface ToastProps {
  id?: number
  content: any
  type?: ToastType
  onClose?: () => void
  children?: React.ReactNode
}

const Toast: React.FC<ToastProps> = ({ children, type, id, onClose }) => {
  const { removeToast } = useToasts()
  return (
    <div className={classnames(styles.toast, styles[`is-${type}`])}>
      <button
        className={classnames(styles.dismissButton)}
        onClick={() => {
          removeToast(id)
          onClose?.()
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      {children}
    </div>
  )
}

Toast.defaultProps = {
  type: 'primary'
}

let toastCount = 0

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([])
  const addToast = useCallback(
    (content, type = 'primary', onClose) => {
      const toast = {
        content,
        id: toastCount++,
        type: type,
        onClose
      } as ToastProps
      setToasts([...toasts, toast])
    },
    [setToasts]
  )

  const removeToast = useCallback(
    (id) => {
      const newToasts = toasts.filter((t) => t.id !== id)
      setToasts(newToasts)
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  )
}

export const useToasts = () => useContext(ToastContext)
