import { useRouter } from 'next/router'
import { useState, useEffect, useContext } from 'react'
import { PlayerContext } from '../utils/song_player'
import styles from './_splashScreen.module.scss'
import React from 'react'
import { Logo } from './Logo'

// TODO: this should work on routing although doesn't work for the first load. why
// how to know when we've just started the app? pass a prop true, but needs a callback outside routing when everything is done. Apollo?

export default function SplashScreen() {
  const router = useRouter()
  const [loading, setLoading] = useState(true)
  const { stop: stopPlayingSong } = useContext(PlayerContext)

  const handleStart = () => {
    setLoading(true)
  }
  const handleComplete = () => {
    setLoading(false)
  }
  const handleError = () => {
    setLoading(false)
  }

  useEffect(() => {
    if (window.document.readyState !== 'complete') {
      window.addEventListener('load', handleComplete)
    } else {
      handleComplete()
    }

    return () => {
      window.removeEventListener('load', handleComplete)
    }
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleError)
    router.events.on('beforeHistoryChange', stopPlayingSong)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleError)
    }
  })

  return loading && <SplashScreenPure />
}

export const SplashScreenPure = () => (
  <div className={styles.loader}>
    <Logo width={100} height={77} />
  </div>
)
