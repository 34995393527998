import { createContext, useState } from 'react'

interface PlayerState {
  player: Player
  isPlaying: boolean
  isLoading: boolean
  activeSongId?: number
}

interface PlayerContextInterface {
  playerState: PlayerState
  playOrPause: (song: Song) => void
  stop: () => void
}

interface Song {
  id: number
  url: string
}
class Player {
  public onChangeState: (state: PlayerState) => void
  private audio: HTMLAudioElement
  private isPlaying: boolean
  private isLoading: boolean
  private activeSongId: number
  private audioCache: any
  private played: any
  private faderSettings: any
  private fadeOutTimeout

  constructor() {
    this.isPlaying = false
    this.onChangeState = () => {}
    this.audioCache = {}
    this.played = {}
    this.faderSettings = {
      rampTime: 3000,
      tick: 50,
      fadeInTargetVolume: 1,
      fadeOutTargetVolume: 0
    }
    const {
      rampTime,
      tick,
      fadeInTargetVolume: targetVolume
    } = this.faderSettings
    this.faderSettings.volumeIncrease = targetVolume / (rampTime / tick)
  }

  getAudio() {
    return this.audio
  }

  playOrPause(song: Song) {
    if ((song.url && song.url.length === 0) || !song.url) return
    if (this.isLoading) return

    const doPause = this.isPlaying && this.isActiveSong(song)
    this.pause()
    if (!doPause) this.play(song)
  }

  isActiveSong(song: Song): boolean {
    return this.activeSongId === song.id
  }

  isPaused(song: Song): boolean {
    return this.isActiveSong(song) && !this.isPlaying
  }
  pause() {
    this.audio?.pause()
    this.isPlaying = false
  }

  stop() {
    this.pause()
    this.activeSongId = null
  }

  state(): PlayerState {
    return {
      player: this,
      isPlaying: this.isPlaying,
      activeSongId: this.activeSongId,
      isLoading: this.isLoading
    }
  }

  ensureIsPlaying() {
    return this.isPlaying
  }

  ensureIsPlayingStateActual() {
    const progress = setInterval(() => {
      if (this.isPlaying) {
        if (this.audio.currentTime >= this.audio.duration) {
          this.isPlaying = false
        }
      } else {
        clearInterval(progress)
      }
    }, 1000)
  }

  private play(song: Song) {
    clearTimeout(this.fadeOutTimeout)
    if (!this.isActiveSong(song) || !this.audio) {
      this.audio = this.audioCache[song.url] || new Audio(song.url)
      this.audio.preload = 'metadata'
      this.audio.currentTime = 0
      this.audioCache[song.url] = this.audio
    }

    const audio = this.audio
    this.activeSongId = song.id
    this.isPlaying = true

    const startsPlayingFromBeginning = this.audio.currentTime === 0
    const playedBefore = this.played[audio.src]
    if (startsPlayingFromBeginning) {
      this.fadeIn() // starts playing from the beginning
    }
    if (playedBefore) {
      this.setupFadeOut()
    } else {
      // first time play - it will load metadata to get audio.duration
      audio.onloadedmetadata = () => {
        this.setupFadeOut()
      }
    }
    this.handleAudioLoading()
    audio.play()
    this.ensureIsPlayingStateActual()
    this.played[audio.src] = true
  }

  private handleAudioLoading() {
    this.audio.onloadstart = () => {
      this.isLoading = true
      this.onChangeState(this.state())
    }
    this.audio.onloadeddata = () => {
      this.isLoading = false
      this.onChangeState(this.state())
    }
  }

  private setupFadeOut() {
    const startFadeOutAt =
      this.audio.duration -
      this.audio.currentTime -
      this.faderSettings.rampTime / 1000
    this.fadeOutTimeout = setTimeout(() => {
      this.fadeOut()
    }, startFadeOutAt * 1000)
  }

  private fadeIn() {
    const {
      fadeInTargetVolume: targetVolume,
      volumeIncrease,
      tick
    } = this.faderSettings

    const ramp = () => {
      const vol = Math.min(targetVolume, this.audio.volume + volumeIncrease)
      this.audio.volume = vol
      if (this.audio.volume < targetVolume) setTimeout(ramp, tick)
    }

    const startRampUp = () => {
      this.audio.removeEventListener('playing', null)
      ramp()
    }

    this.audio.volume = 0
    this.audio.addEventListener('playing', startRampUp)
  }

  private fadeOut() {
    const {
      fadeOutTargetVolume: targetVolume,
      rampTime,
      tick
    } = this.faderSettings
    const volumeStep = (this.audio.volume - targetVolume) / (rampTime / tick)

    const ramp = () => {
      const vol = Math.max(0, this.audio.volume - volumeStep)
      this.audio.volume = vol

      if (this.audio.volume > targetVolume) {
        setTimeout(ramp, tick)
      } else {
        this.pause()
        this.audio = null // will be placed back from cache once played again
        this.onChangeState(this.state())
      }
    }
    ramp()
  }
}

const PlayerContext = createContext<PlayerContextInterface>({
  playerState: {
    player: new Player(),
    isPlaying: false,
    isLoading: false
  },
  playOrPause: () => {},
  stop: () => {}
})

const PlayerProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const player = new Player()

  const [playerState, setPlayerState] = useState({
    player,
    isPlaying: false,
    isLoading: false
  })

  player.onChangeState = setPlayerState
  const playOrPause = (song: Song) => {
    playerState.player.playOrPause(song)
    setPlayerState(playerState.player.state())
  }
  const stop = () => {
    playerState.player.stop()
    setPlayerState(playerState.player.state())
  }

  return (
    <PlayerContext.Provider value={{ playerState, playOrPause, stop }}>
      {children}
    </PlayerContext.Provider>
  )
}

export { PlayerContext, PlayerProvider }
