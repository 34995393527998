import { gql } from '../../__generated__/gql'

export const SONG_DETAILS_QUERY = gql(`
  query songDetails($id: Int!) {
    song(id: $id) {
      id
      title
      slug
      seoKeywords
      seoDescription
      sampleUrl
      writer
      favorite
      album {
        id
        name
        year
        primaryArtist {
          id
        }
        cover {
          medium
        }
      }
      artists {
        id
        name
      }
      themes {
        id
        name
      }
      genres {
        id
        name
      }
      ccliNumber
      appleMusicUrl
      spotifyUrl
      bpm
      key
      timeSignature
      copyrights
      vibe
      voice {
        id
        name
      }
      hasKeyPatches
      hasMultiTracks
      difficulty
      audioMixerTracks {
        id
        audioUrlEncoded
        instrument
        songKey
      }
      userSetup {
        id
        charts
        lyrics
        mixer
        capo
        songKey
        songPcoInfo
      }
      devotional
    }
  }
`)
