import { useEffect, useState } from 'react'
import React from 'react'
import styles from './_devicesLimitModal.module.scss'
import { useAuth } from '../../utils/auth'
import { Modal } from '../Modal'
import DevicesModal from './DevicesModal'
import { t } from '../../utils'

export default function DevicesLimitModal() {
  const [isOpen, setIsOpen] = useState(false)

  const { currentUser, isLoading } = useAuth()

  useEffect(() => {
    if (currentUser) {
      setIsOpen(currentUser.devicesLimitExceeded)
    }
    if (!currentUser && !isLoading) {
      setIsOpen(false)
    }
  }, [currentUser, isLoading])

  if (!currentUser) return

  return (
    <Modal
      isOpen={isOpen}
      title={t('Device limit of three (3) reached for your account')}
      hideCloseButton={true}
      modifier={styles.devicesModalModifier}
    >
      <DevicesModal />
    </Modal>
  )
}
