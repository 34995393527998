const t = (text: string): string => text

const pluralize = (text: string, count: number): string => {
  if (count === 1) {
    return text
  } else {
    return `${text}s`
  }
}

const rolesTitles = {
  team_leader: t('Leader'),
  music_director: t('Setlist Planner'),
  musician: t('Musician/Vocalist')
}

const instrumentTitles = {
  acoustic_guitar: t('Acoustic Guitar'),
  guitar: t('Electric Guitar'),
  keys: t('Keys'),
  drums: t('Drums'),
  bass: t('Bass'),
  vocals: t('Vocals')
}

export { t, pluralize, rolesTitles, instrumentTitles }
