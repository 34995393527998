import { Fragment, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'

import styles from './_modal.module.scss'

interface ModalProps {
  isOpen: boolean
  onHide: () => void
  title: string
  modifier?: string
  hideCloseButton?: boolean
  children?: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  modifier,
  hideCloseButton,
  onHide
}) => {
  const modalWrapClassName = 'modalWrapper'

  return createPortal(
    <Fragment>
      <div className={styles.modalOverlay} />
      <div
        className={`${styles[modalWrapClassName]}`}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
        onClick={(event) => {
          const target = event.target as HTMLElement
          if (target.className?.includes) {
            if (target.className.includes(modalWrapClassName)) {
              onHide()
            }
          }
        }}
      >
        <div
          className={classnames(
            { [modifier]: modifier && modifier.length > 0 },
            styles.modal
          )}
        >
          <header className={styles.modalHeader}>
            <h1>{title}</h1>
            {!hideCloseButton && (
              <button
                type="button"
                className={styles.modalClose}
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <FontAwesomeIcon
                  aria-hidden="true"
                  icon={faTimes}
                  className={styles.modalCloseIcon}
                />
              </button>
            )}
          </header>
          <div className={styles.modalContainer}>{children}</div>
        </div>
      </div>
    </Fragment>,
    document.body
  ) as ReactNode
}
