import dynamic from 'next/dynamic'
import { t } from '../utils/i18n'
import PageHead from './PageHead'
import DevicesLimitModal from './modals/DevicesLimitModal'

const ReactivateBlock = dynamic(() => import('./ReactivateBlock'), {
  ssr: false
})
const AdminSpoofingBlock = dynamic(() => import('./AdminSpoofingBlock'), {
  ssr: false
})
const Header = dynamic(() => import('./Header'))
const Footer = dynamic(() => import('./Footer'))

interface LayoutProps {
  hasSearch?: boolean
  pageTitle?: string
  pageDescription?: string
  keywords?: string
  thumbnail?: string
  ogImage?: string
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({
  children,
  hasSearch,
  pageTitle,
  pageDescription,
  keywords,
  thumbnail,
  ogImage
}) => {
  return (
    <>
      <AdminSpoofingBlock />
      <ReactivateBlock />
      <DevicesLimitModal />
      <PageHead
        title={pageTitle}
        description={pageDescription}
        keywords={keywords}
        thumbnail={thumbnail}
        ogImage={ogImage}
      />
      <div className="header-container">
        <Header hasSearch={hasSearch} />
      </div>
      {children}
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  hasSearch: true,
  pageTitle: t('WorshipOnline - Tutorials and Lessons For Instruments!'),
  pageDescription: t(
    'WorshipOnline guitar, keys, vocals, bass, and drum tutorials! Never show up unprepared on Sunday again in half the time! Lessons, chords, tabs, and more!'
  )
}

export default Layout
