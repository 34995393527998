import { gql } from '../../__generated__/gql'

export const SONGS_LIST_QUERY = gql(`
  query songsListQuery(
    $q: String
    $page: Int
    $perPage: Int
    $songKey: String
    $timeSignature: String
    $bpmRange: [Int!]
    $vibeId: VibeEnum
    $voiceId: Int
    $difficultyIds: [String!]
    $genreIds: [Int!]
    $themeIds: [Int!]
    $artistIds: [Int!]
    $albumIds: [Int!]
    $orderBy: SortSongsEnum
    $favorite: Boolean
  ) {
    songs(
      q: $q
      page: $page
      perPage: $perPage
      songKey: $songKey
      timeSignature: $timeSignature
      bpmRange: $bpmRange
      vibeId: $vibeId
      voiceId: $voiceId
      difficultyIds: $difficultyIds
      genreIds: $genreIds
      themeIds: $themeIds
      artistIds: $artistIds
      albumIds: $albumIds
      orderBy: $orderBy
      favorite: $favorite
    ) {
      ...songFields
    }
  }
`)
