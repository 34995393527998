import { useApolloClient, useLazyQuery } from '@apollo/client'
import { useContext } from 'react'
import { createContext, useEffect, useState } from 'react'
import {
  CURRENT_USER_QUERY,
  SONGS_LIST_QUERY,
  SONG_CHORDS_QUERY,
  SONG_DETAILS_QUERY,
  SONG_LYRICS_QUERY,
  SONG_TAB_INFOS_QUERY,
  SONG_VIDEO_QUERY
} from '../../gql/queries'
import { getOperationName } from '@apollo/client/utilities'
import React from 'react'
import { sha256 } from 'js-sha256'
import { User } from '../../__generated__/graphql'
import { useRouter } from 'next/router'

const refreshCurrentUserQueries = () => {
  return [
    getOperationName(SONG_DETAILS_QUERY),
    getOperationName(SONG_LYRICS_QUERY),
    getOperationName(SONG_CHORDS_QUERY),
    getOperationName(SONG_TAB_INFOS_QUERY),
    getOperationName(SONG_VIDEO_QUERY),
    getOperationName(SONGS_LIST_QUERY)
  ]
}
interface AuthContextInterface {
  currentUserFavorites: Set<number>
  currentUser: User
  isLoading: boolean
  isAuthenticated: boolean
  isAdmin: boolean
  isTeamAdmin: boolean
  isTeamLeader: boolean
  isMusicDirector: boolean
  isMusician: boolean
  setCurrentUser: (user: User) => void
  refetchCurrentUser: () => void
}

const AuthContext = createContext<AuthContextInterface>({
  currentUserFavorites: null,
  currentUser: null,
  isLoading: false,
  isAuthenticated: false,
  isAdmin: false,
  isTeamAdmin: false,
  isTeamLeader: false,
  isMusicDirector: false,
  isMusician: false,
  setCurrentUser: () => {},
  refetchCurrentUser: () => {}
})

interface AuthState {
  user: User
  isAuthenticated: boolean
  isAdmin: boolean
  isTeamAdmin: boolean
  isTeamLeader: boolean
  isMusicDirector: boolean
  isMusician: boolean
  currentUserFavorites: Set<number>
}

const userToState = (user: User): AuthState => {
  return {
    user,
    isAuthenticated: !!user,
    isAdmin: Boolean(user?.roles?.includes('admin')),
    isTeamAdmin: Boolean(user?.roles?.includes('team_admin')),
    isTeamLeader: Boolean(user?.roles?.includes('team_leader')),
    isMusicDirector: Boolean(user?.roles?.includes('music_director')),
    isMusician: Boolean(user?.roles?.includes('musician')),
    currentUserFavorites: new Set(user?.favorites)
  }
}

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const router = useRouter()
  const client = useApolloClient()
  let data
  try {
    data = client.readQuery({ query: CURRENT_USER_QUERY })
  } catch {
    data = { currentUser: null }
  }

  const [state, setState] = useState<AuthState>(
    userToState(data?.currentUser || null)
  )

  const setCurrentUser = (user: User) => {
    client.writeQuery({
      query: CURRENT_USER_QUERY,
      data: {
        currentUser: user
      }
    })
    setState(userToState(user))
  }

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [fetchUser] = useLazyQuery(CURRENT_USER_QUERY, {
    onCompleted: (data) => {
      setCurrentUser(data.currentUser as User)
      setIsLoading(false)
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    fetchUser()
    router.events.on('routeChangeStart', client.resetStore)
    return () => router.events.off('routeChangeStart', client.resetStore)
  }, [])

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_INTERCOM_APP_ID) {
      if (state.user) {
        const userHash = sha256.hmac(
          process.env.NEXT_PUBLIC_INTERCOM_SECRET_KEY,
          state.user.email
        )
        window.intercomSettings = {
          name: `${state.user.firstName} ${state.user.lastName}`,
          email: state.user.email,
          user_hash: userHash
        }
      } else {
        window.Intercom?.('shutdown')
        window.intercomSettings = null
      }
      window.Intercom?.('update')
    }
  }, [state.user])

  const authValue: AuthContextInterface = React.useMemo(
    () => ({
      currentUserFavorites: state.currentUserFavorites,
      currentUser: state.user,
      isAuthenticated: state.isAuthenticated,
      isAdmin: state.isAdmin,
      isTeamAdmin: state.isTeamAdmin,
      isTeamLeader: state.isTeamLeader,
      isMusicDirector: state.isMusicDirector,
      isMusician: state.isMusician,
      refetchCurrentUser: fetchUser,
      isLoading,
      setCurrentUser
    }),
    [state.user, isLoading]
  )

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  )
}

const useAuth = (): AuthContextInterface => {
  return useContext(AuthContext)
}

export { useAuth, refreshCurrentUserQueries }
